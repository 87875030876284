import React, {useRef} from "react";
import BottomNav from "./BottomNav";
import { useDispatch, useSelector } from "react-redux";
import Keyboard from "react-simple-keyboard";
import "react-simple-keyboard/build/css/index.css";
//import {sendSMScode} from "../misc/nexmo";
import {useHistory} from "react-router-dom";
//import {sendEmailcode} from "../misc/smtp2go";
import {sendMAIL, sendSMS} from "../misc/api";

function Page5() {

    let history = useHistory();

    const debug = useSelector( ( state ) => state.debug );
    // const debug = false;
    const code = useSelector( ( state ) => state.code );
    const generated_code = useSelector( ( state ) => state.generated_code );
    const type_reception_code = useSelector( ( state ) => state.type_reception_code );
    const envoie_code_en_cours = useSelector( ( state ) => state.envoie_code_en_cours );
    const demandeur_telephone = useSelector( ( state ) => state.demandeur_telephone );
    const demandeur_email = useSelector( ( state ) => state.demandeur_email );
    const logged_in = useSelector( ( state ) => state.logged_in );
    const dispatch = useDispatch();

    const keyboard = useRef()

    const setTypeEnvoieCode = type => {

        let x_code = Math.floor(1000 + Math.random() * 9000);
        dispatch( { type: 'set_generated_code', payload: x_code } )

        if( type === 'sms' ){

            let formated_phone_number = null;

            dispatch( { type: 'set_envoie_code_en_cours', payload: true } )
            dispatch( { type: 'set_type_reception_code', payload: type } )


            if( demandeur_telephone.startsWith( '00' ) ){
                formated_phone_number = demandeur_telephone.substring(2);
            } else if( demandeur_telephone.startsWith( '+' ) ){
                formated_phone_number = demandeur_telephone.substring(1);
            } else if( demandeur_telephone.startsWith( '0' ) ){
                formated_phone_number = '32' + demandeur_telephone.substring(1);
            }

            sendSMS( formated_phone_number, x_code,
                () => dispatch( { type: 'set_envoie_code_en_cours', payload: false } ),
                () => {
                    dispatch( { type: 'set_envoie_code_en_cours', payload: false } );
                    dispatch( { type: 'set_generated_code', payload: null } );
                    dispatch( { type: 'set_type_reception_code', payload: null } );
                }
            )

        }
        else if( type === 'mail' ){

            console.log('envoie email');

            dispatch( { type: 'set_type_reception_code', payload: type } )
            dispatch( { type: 'set_envoie_code_en_cours', payload: true } )

            sendMAIL( demandeur_email, x_code,
                () => dispatch( { type: 'set_envoie_code_en_cours', payload: false } ),
                () => {
                    dispatch( { type: 'set_envoie_code_en_cours', payload: false } );
                    dispatch( { type: 'set_generated_code', payload: null } );
                    dispatch( { type: 'set_type_reception_code', payload: null } );
                });

        }
    };

    const onChange = input => {

        dispatch( { type: 'set_code', payload: input } );

        if( input.length === 4 ){
            if( input === generated_code.toString() ){
                dispatch( { type: 'set_logged_in', payload: true } );
                dispatch( { type: 'set_code', payload: '' } );
                history.push("/Page6");

            } else {
                dispatch( { type: 'set_code', payload: '' } );
            }
        }

    };

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            {
                envoie_code_en_cours === true ?
                    <div className="mt-2">
                        <h1 className="text-center font-gold mt-3">envoi en cours</h1>
                    </div>
                    : null
            }
            {
                envoie_code_en_cours === false && type_reception_code === null ?
                    <div className="mt-2">
                        <h1 className="text-center font-gold mt-3">Recevoir le code par</h1>
                        <div className="row">
                            <div className="col-6 mb-3">
                                <button type="button" className={ "btn btn-lg btn-block btn-light" } onClick={ () => setTypeEnvoieCode( 'sms' ) }>SMS</button>
                            </div>
                            <div className="col-6 mb-3">
                                <button type="button" className={ "btn btn-lg btn-block btn-light" } onClick={ () => setTypeEnvoieCode( 'mail' ) }>email</button>
                            </div>
                        </div>
                    </div>
                    : null
            }
            {
                envoie_code_en_cours === false && type_reception_code !== null ?
                    <div className="mt-2">
                        <h1 className="text-center font-gold mt-3">Code envoyé</h1>
                        {
                            type_reception_code === 'sms' && <h4 className="mb-3 text-center">Un code vous a été envoyé { type_reception_code === 'sms' ? 'SMS' : ( type_reception_code === 'mail' ? 'email' : 'inconnu' ) } au numéro { demandeur_telephone }.<br/>Entrez le code ci-dessous.</h4>
                        }
                        {
                            type_reception_code === 'mail' && <h4 className="mb-3 text-center">Un code vous a été envoyé { type_reception_code === 'sms' ? 'SMS' : ( type_reception_code === 'mail' ? 'email' : 'inconnu' ) } à l'adresse { demandeur_email }<br/>Entrez le code ci-dessous.</h4>
                        }
                        <div className="row">
                            <div className="col-4 m-auto">
                                <form autoComplete="off">
                                    <div className="form-group">
                                        <input type="code" name="code" max="4" value={code} className="form-control form-control-lg" onChange={ onChange } />
                                        { debug && <small>{ generated_code }</small> }
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6 m-auto">
                                <Keyboard
                                    keyboardRef={r => (keyboard.current = r)}
                                    layoutName="default"
                                    onChange={onChange}
                                    inputName="code"
                                    layout={
                                        {
                                            default: [
                                                '1 2 3',
                                                '4 5 6',
                                                '7 8 9',
                                                '0 {bksp}',
                                            ]
                                        }
                                    }
                                    display={
                                        {
                                            '{bksp}': 'supp.'
                                        }
                                    }
                                    buttonTheme={
                                        [
                                            {
                                                class: "bg-info btn btn-info",
                                                buttons: "{enter}"
                                            }
                                        ]
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    : null
            }
        </div>
            <BottomNav precedent_page={'/Page4'} suivant_page={'/Page6'}  suivant_enable={ logged_in }  />
        </>
    );

}

export default Page5;
