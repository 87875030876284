import {updateChoixArticle} from "../misc/choix_article";

const _debug = false;

const initState = {

    debug: _debug,

    coordonnee: {
        latitude: 50.80983486,
        longitude: 4.404093440,
    },

    devis_reference: null,

    demande_concerne: null,
    qui: _debug ? 2 : null,
    qui_nom: _debug ? 'Ducobu' : '',
    qui_prenom: _debug ? 'Francois' : '',
    demandeur_nom: _debug ? 'Verstappen' : '',
    demandeur_prenom: _debug ? 'Marjorie' : '',
    demandeur_email: _debug ? 'francois.ducobu@gmail.com' : '',
    demandeur_telephone: _debug ? '0032489312378' : '',

    generated_code: null,
    code: '',
    type_reception_code: null,
    envoie_code_en_cours: false,

    logged_in: _debug,

    deces_lieu_type: null,
    deces_lieu_ville: '',
    deces_lieu_hopital_poi: null,
    deces_lieu_maison_de_repos_poi: null,
    deces_lieu_ville_poi: null,

    obseque_type: _debug ? 1 : null,

    ceremonie_type: null,
    ceremonie_civile_type: null,
    ceremonie_religieuse_type: null,
    ceremonie_no_type: null,

    ceremonie_lieu_type: null,
    ceremonie_lieu_id: null,

    ceremonie_religieuse_poi: null,
    ceremonie_civile_poi: null,

    crematorium_poi: null,
    cimetiere_poi: null,

    devenir_des_cendres: null,
    lieu_dispersion: null,

    concession_existante: null,
    concession_existante_type: null,

    concession_existante_caveau_monument: null,
    concession_existante_caveau_monument_ouverture: null,
    concession_existante_pleine_terre_monument: null,
    concession_existante_nombre_de_place_pleine_terre_avec_monument: null,
    concession_existante_nombre_de_place_pleine_terre_sans_monument: null,

    concession_nouvelle_type: null,
    concession_nouvelle_nombre_de_place_caveau: null,
    concession_nouvelle_nombre_de_place_pleine_terre: null,

    concession_option_gravure: false,
    concession_option_remise_a_neuf_gravure: false,
    concession_option_monument_temporaire: false,

    recueillement: null,
    lieu_de_repos_type: null,
    lieu_de_repos_funerarium: null,
    preparation_du_defunt: null,

    offset_list_urne: 0,
    urne_selectionne: null,

    offset_list_cercueil: 0,
    cercueil_selectionne: null,

    offset_list_capiton: 0,
    capiton_selectionne: null,

    offset_list_embleme: 0,
    embleme_selectionne: null,

    corbillard: null,

    prestation_complementaire: [],
    prestation_offertes: [0,1,2,3,4,5,6,7,8,9],

    list_cercueil: [],
    list_capiton: [],
    list_embleme: [],
    list_article: [],
    list_urne: [],

    list_choix_articles: [],

    categorie_normalise: [
        {
            id: 1,
            label: 'Préparation/Organisation Des Obsèques'
        },
        {
            id: 2,
            label: 'Transport Du Défunt Avant Mise En Bière'
        },
        {
            id: 3,
            label: 'Cercueil Et Accessoires'
        },
        {
            id: 4,
            label: 'Mise En Bière Et Fermeture Du Cercueil'
        },
        {
            id: 5,
            label: 'Transport Du Défunt Après Mise En Bière'
        },
        {
            id: 6,
            label: 'Cérémonie Funéraire'
        },
        {
            id: 7,
            label: 'Inhumation'
        },
        {
            id: 8,
            label: 'Crémation'
        },
    ]

}

const rootReducer = ( state = initState, action ) => {

    let new_state = null;
    let list_article = [];

    switch( action.type ){
        case 'set_demande_concerne':

            new_state = {
                ...state,
                demande_concerne: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_qui':
            return {
                ...state,
                qui: action.payload
            }
        case 'set_qui_nom':
            return {
                ...state,
                qui_nom: action.payload
            }
        case 'set_qui_prenom':
            return {
                ...state,
                qui_prenom: action.payload
            }
        case 'set_demandeur_nom':
            return {
                ...state,
                demandeur_nom: action.payload
            }
        case 'set_demandeur_prenom':
            return {
                ...state,
                demandeur_prenom: action.payload
            }
        case 'set_demandeur_email':
            return {
                ...state,
                demandeur_email: action.payload
            }
        case 'set_demandeur_telephone':
            return {
                ...state,
                demandeur_telephone: action.payload
            }
        case 'set_code':
            return {
                ...state,
                code: action.payload
            }
        case 'set_logged_in':
            return {
                ...state,
                logged_in: action.payload
            }
        case 'set_generated_code':
            return {
                ...state,
                generated_code: action.payload
            }
        case 'set_deces_lieu_type':

            new_state = {
                ...state,
                deces_lieu_type: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_deces_lieu_ville':
            return {
                ...state,
                deces_lieu_ville: action.payload
            }
        case 'set_obseque_type':

            new_state = {
                ...state,
                obseque_type: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_ceremonie_type':

            new_state = {
                ...state,
                ceremonie_type: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_ceremonie_civile_type':

            new_state = {
                ...state,
                ceremonie_civile_type: action.payload,
            }

            // list_article = updateChoixArticle( new_state );
            //
            // new_state = {
            //     ...new_state,
            //     list_choix_articles: list_article,
            // }

            return new_state

        case 'set_ceremonie_religieuse_type':

            new_state = {
                ...state,
                ceremonie_religieuse_type: action.payload,
            }

            // list_article = updateChoixArticle( new_state );
            //
            // new_state = {
            //     ...new_state,
            //     list_choix_articles: list_article,
            // }

            return new_state

        case 'set_ceremonie_no_type':

            new_state = {
                ...state,
                ceremonie_no_type: action.payload,
            }

            // list_article = updateChoixArticle( new_state );
            //
            // new_state = {
            //     ...new_state,
            //     list_choix_articles: list_article,
            // }

            return new_state

        case 'set_concession_existante':
            return {
                ...state,
                concession_existante: action.payload
            }

        case 'set_concession_existante_type':

            new_state = {
                ...state,
                concession_existante_type: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_concession_existante_caveau_monument':

            new_state = {
                ...state,
                concession_existante_caveau_monument: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_concession_existante_caveau_monument_ouverture':

            new_state = {
                ...state,
                concession_existante_caveau_monument_ouverture: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state;



        case 'set_concession_existante_pleine_terre_monument':

            new_state = {
                ...state,
                concession_existante_pleine_terre_monument: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state;

        case 'set_concession_existante_nombre_de_place_pleine_terre_avec_monument':

            new_state = {
                ...state,
                concession_existante_nombre_de_place_pleine_terre_avec_monument: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state;

        case 'set_concession_existante_nombre_de_place_pleine_terre_sans_monument':

            new_state = {
                ...state,
                concession_existante_nombre_de_place_pleine_terre_sans_monument: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state;

        case 'set_concession_nouvelle_type':

            new_state = {
                ...state,
                concession_nouvelle_type: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state;

        case 'set_concession_nouvelle_nombre_de_place_caveau':

            new_state = {
                ...state,
                concession_nouvelle_nombre_de_place_caveau: action.payload
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_concession_nouvelle_nombre_de_place_pleine_terre':

            new_state = {
                ...state,
                concession_nouvelle_nombre_de_place_pleine_terre: action.payload
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_concession_option_gravure':
            return {
                ...state,
                concession_option_gravure: action.payload
            }
        case 'set_concession_option_remise_a_neuf_gravure':
            return {
                ...state,
                concession_option_remise_a_neuf_gravure: action.payload
            }
        case 'set_concession_option_monument_temporaire':

            new_state = {
                ...state,
                concession_option_monument_temporaire: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_recueillement':

            new_state = {
                ...state,
                recueillement: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_lieu_de_repos_type':

            new_state = {
                ...state,
                lieu_de_repos_type: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_lieu_de_repos_funerarium':
            return {
                ...state,
                lieu_de_repos_funerarium: action.payload
            }
        case 'set_preparation_du_defunt':

            new_state = {
                ...state,
                preparation_du_defunt: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_cercueil_selectionne':

            new_state = {
                ...state,
                cercueil_selectionne: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_urne_selectionne':

            new_state = {
                ...state,
                urne_selectionne: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_offset_list_urne':
            return {
                ...state,
                offset_list_urne: action.payload
            }

        case 'set_offset_list_cercueil':
            return {
                ...state,
                offset_list_cercueil: action.payload
            }

        case 'set_offset_list_embleme':
            return {
                ...state,
                offset_list_embleme: action.payload
            }

        case 'set_offset_list_capiton':
            return {
                ...state,
                offset_list_capiton: action.payload
            }

        case 'set_capiton_selectionne':

            new_state = {
                ...state,
                capiton_selectionne: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_embleme_selectionne':

            new_state = {
                ...state,
                embleme_selectionne: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_corbillard':

            new_state = {
                ...state,
                corbillard: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_prestation_complementaire':

            let new_presta = [ ...state.prestation_complementaire ];

            if( action.payload.type === 'add' ){
                new_presta.push( action.payload.index );
            } else {
                new_presta = new_presta.filter(item => item !== action.payload.index)
            }

            new_state = {
                ...state,
                prestation_complementaire: new_presta,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_prestation_offertes':

            let new_presta_offerte = [ ...state.prestation_offertes ];

            if( action.payload.type === 'add' ){
                new_presta_offerte.push( action.payload.index );
            } else {
                new_presta_offerte = new_presta_offerte.filter(item => item !== action.payload.index)
            }

            new_state = {
                ...state,
                prestation_offertes: new_presta_offerte,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_type_reception_code':
            return {
                ...state,
                type_reception_code: action.payload
            }
        case 'set_envoie_code_en_cours':
            return {
                ...state,
                envoie_code_en_cours: action.payload
            }

        case 'set_list_urne':
            return {
                ...state,
                list_urne: action.payload
            }

        case 'set_list_cercueil':
            return {
                ...state,
                list_cercueil: action.payload
            }
        case 'set_list_capiton':
            return {
                ...state,
                list_capiton: action.payload
            }
        case 'set_list_embleme':
            return {
                ...state,
                list_embleme: action.payload
            }

        case 'set_list_article':
            return {
                ...state,
                list_article: action.payload
            }

        case 'set_ceremonie_religieuse_poi':
            return {
                ...state,
                ceremonie_religieuse_poi: action.payload
            }

        case 'set_deces_lieu_ville_poi':
            return {
                ...state,
                deces_lieu_ville_poi: action.payload
            }

        case 'set_deces_lieu_hopital_poi':
            return {
                ...state,
                deces_lieu_hopital_poi: action.payload
            }

        case 'set_deces_lieu_maison_de_repos_poi':
            return {
                ...state,
                deces_lieu_maison_de_repos_poi: action.payload
            }

        case 'set_ceremonie_civile_poi':
            return {
                ...state,
                ceremonie_civile_poi: action.payload
            }

        case 'set_crematorium_poi':

            new_state = {
                ...state,
                crematorium_poi: action.payload,
            }

            list_article = updateChoixArticle( new_state );

            new_state = {
                ...new_state,
                list_choix_articles: list_article,
            }

            return new_state

        case 'set_devenir_des_cendres':
            return {
                ...state,
                devenir_des_cendres: action.payload
            }

        case 'set_lieu_dispersion':
            return {
                ...state,
                lieu_dispersion: action.payload
            }

        case 'set_cimetiere_poi':
            return {
                ...state,
                cimetiere_poi: action.payload
            }

        case 'set_devis_reference':
            return {
                ...state,
                devis_reference: action.payload
            }

        case 'set_article_from_api':

            return {
                ...state,
                list_article: action.payload.article,
                list_cercueil: action.payload.cercueil,
                list_capiton: action.payload.capiton,
                list_embleme: action.payload.embleme,
                list_urne: action.payload.urne,
            };

        default:
            return state;
    }
}

export default rootReducer;
