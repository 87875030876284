import React from "react";
import BottomNav from "./BottomNav";
import {list_ceremonie_civile_type, list_ceremonie_religieuse_type, list_ceremonie_type} from '../misc/listing'
import {useDispatch, useSelector} from "react-redux";
import POI from "./sub/POI";

function Page7() {

    const ceremonie_type = useSelector( ( state ) => state.ceremonie_type );
    const ceremonie_civile_type = useSelector( ( state ) => state.ceremonie_civile_type );
    const ceremonie_religieuse_type = useSelector( ( state ) => state.ceremonie_religieuse_type );
    const ceremonie_religieuse_poi = useSelector( ( state ) => state.ceremonie_religieuse_poi );
    const obseque_type = useSelector( ( state ) => state.obseque_type );
    const crematorium_poi = useSelector( ( state ) => state.crematorium_poi );
    //const ceremonie_civile_poi = useSelector( ( state ) => state.ceremonie_civile_poi );
    const dispatch = useDispatch();

    return (
        <>
        <div className="container flex-grow-1 d-flex justify-content-center">
            <div className="mt-2">
                <h1 className="text-center font-gold mt-5">Quel type de cérémonie désirez-vous?</h1>
                <div className="row justify-content-around mb-5">
                    {
                        list_ceremonie_type.map((value, index) => {
                            return (
                                <div className="col-4 mb-3" key={ index }>
                                    <button type="button" className={ "btn btn-lg btn-block" + ( index === ceremonie_type ? ' btn-warning' : ' btn-light' ) } onClick={ () => dispatch( { type: 'set_ceremonie_type', payload: index } ) }>{ value.label }</button>
                                </div>
                            );
                        })
                    }
                </div>
                {
                    ceremonie_type !== null && ceremonie_type !== 2 && <>
                        <h1 className="text-center font-gold mt-5">Sélectionnez le lieu de la cérémonie</h1>
                        {
                            ceremonie_type === 0 ?
                                <>
                                    <div className="row justify-content-around mb-5">
                                        {
                                            list_ceremonie_civile_type.map((value, index) => {
                                                if ( obseque_type === 1 || ( obseque_type === 0 && !value.seulement_si_cremation ) ){
                                                    return (
                                                        <div className="col-6 mb-3" key={index}>
                                                            <button type="button"
                                                                    className={"btn btn-lg btn-block" + (index === ceremonie_civile_type ? ' btn-warning' : ' btn-light')}
                                                                    onClick={() => dispatch({
                                                                        type: 'set_ceremonie_civile_type',
                                                                        payload: index
                                                                    })}>{value.label}</button>
                                                        </div>
                                                    );
                                                }
                                                return null;
                                            })
                                        }
                                    </div>
                                    {
                                        ceremonie_civile_type === 0 && <POI afficher_button_modifier={false} poi={{
                                            name: 'Société Bruxelloise de crémation',
                                            street: 'Avenue du Silence 57',
                                            postal_code: '1180',
                                            city: 'Uccle'
                                        }} />
                                    }
                                    {
                                        ceremonie_civile_type === 1 && crematorium_poi !== null && <POI afficher_button_modifier={false} poi={ crematorium_poi } />
                                    }
                                </>
                                : null
                        }
                        {
                            ceremonie_type === 1 ?
                                <>
                                    <div className="row justify-content-around mb-5">
                                        {
                                            list_ceremonie_religieuse_type.map((value, index) => {
                                                return (
                                                    <div className="col-6 mb-3" key={ index }>
                                                        <button type="button"
                                                                className={ "btn btn-lg btn-block" + ( index === ceremonie_religieuse_type ? ' btn-warning' : ' btn-light' ) }
                                                                onClick={ () => {
                                                                    dispatch( { type: 'set_ceremonie_religieuse_type', payload: index } );
                                                                }}
                                                        >
                                                            { value.label }
                                                        </button>
                                                    </div>
                                                );
                                            })
                                        }
                                    </div>
                                </>
                                : null
                        }
                        {
                            ceremonie_type === 1 && ceremonie_religieuse_type === 0 && <POI poi={ ceremonie_religieuse_poi } route={ '/ChoixEglise' } action_type={ 'set_ceremonie_religieuse_poi' } />
                        }
                    </>
                }

            </div>
        </div>
            <BottomNav precedent_page={ obseque_type === 1 ? '/Page6b' : '/Page6'} suivant_page={'/Page8'} />
        </>
    );

}

export default Page7;
