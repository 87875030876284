import React, {useEffect, useState} from 'react';
import { useIdleTimer } from 'react-idle-timer'
import './App.css';
import Typed from 'react-typed';

import {
    BrowserRouter as Router,
    Switch,
    Route
} from "react-router-dom";

import Page1 from "./components/Page1";
import Page2 from "./components/Page2";
import Page3 from "./components/Page3";
import Page4 from "./components/Page4";
import Page5 from "./components/Page5";
import Page6 from "./components/Page6";
import Page6b from "./components/Page6b";
import Page6c from "./components/Page6c";
import Page7 from "./components/Page7";
import Page8 from "./components/Page8";
import Page9 from "./components/Page9";
import Page10 from "./components/Page10";
import Page11 from "./components/Page11";
import Page12 from "./components/Page12";
import ChoixCercueil from "./components/ChoixCercueil";
import Page14 from "./components/Page14";
import ChoixCorbillard from "./components/ChoixCorbillard";
import ChoixPrestationComplementaire from "./components/ChoixPrestationComplementaire";
import ChoixPrestationOffertes from "./components/ChoixPrestationOffertes";
import Devis from "./components/Devis";
import ChoixCimetiere from "./components/ChoixCimetiere";
import ChoixEglise from "./components/ChoixEglise";
import ChoixHopital from "./components/ChoixHopital";
import ChoixMaisonDeRepos from "./components/ChoixMaisonDeRepos";
import ChoixVille from "./components/ChoixVille";
import ListeArticle from "./components/ListeArticle";
import ChoixCapiton from "./components/ChoixCapiton";
import ChoixEmbleme from "./components/ChoixEmbleme";
import ChoixUrne from "./components/ChoixUrne";
import ChoixCrematorium from "./components/ChoixCrematorium";
import ObtenirMonDevis from "./components/ObtenirMonDevis";

import {library} from '@fortawesome/fontawesome-svg-core'
import {faChevronLeft, faChevronRight, faTimes, faCheck} from '@fortawesome/free-solid-svg-icons'
import {useDispatch, useSelector} from "react-redux";
import {getArticle} from "./misc/api";

library.add(faChevronLeft, faChevronRight, faTimes, faCheck)

function App() {

    const list_article = useSelector( ( state ) => state.list_article );
    const dispatch = useDispatch();

    const [ idle, setIdle ] = useState( false );

    const handleOnIdle = event => {
        setIdle(true);
    }

    const handleOnActive = event => {
        setIdle(false);
        window.location.href = '/';
    }

    //const { getRemainingTime, getLastActiveTime } = useIdleTimer({
    useIdleTimer({
        timeout: 1000 * 600,
        onIdle: handleOnIdle,
        onActive: handleOnActive,
        debounce: 500
    })

    let update_article = () => {
        if( list_article.length === 0 ){
            getArticle()
                .then( response => {
                    console.log(response);
                    if( response.success ){
                        dispatch( { type: 'set_article_from_api', payload: response.data } )
                    }
                })
                .catch( error => console.log( error ) );
        }
    }

    useEffect(update_article, []);

    return (
        <>
            {
                ! idle
                    ? <Router>
                        <div className="d-flex flex-column overflow-hidden min-vh-100 vh-100">
                            <header>
                                <div className="d-flex flex-row">
                                    <div className="col-6">
                                        <img src="/assets/img/logo_compressed.png" alt="logo"/>
                                    </div>
                                    <div className="col-6 d-flex justify-content-end text-right">
                                        <h2 className="my-auto text-white mr-5">
                                            <Typed
                                                strings={['Une question ?', 'Besoin d\'aide ?']}
                                                typeSpeed={60}
                                                backDelay={3000}
                                                showCursor={false}
                                                loop={true}
                                                fadeOut={true}
                                            />
                                            <br/>
                                            Appelez nous au <span className="font-gold">02 792 08 00</span></h2>
                                    </div>
                                </div>
                            </header>
                            <main className="flex-fill d-flex overflow-auto flex-column">
                                <Switch>
                                    <Route path="/ObtenirMonDevis">
                                        <ObtenirMonDevis/>
                                    </Route>
                                    <Route path="/ChoixPrestationOffertes">
                                        <ChoixPrestationOffertes/>
                                    </Route>
                                    <Route path="/ChoixPrestationComplementaire">
                                        <ChoixPrestationComplementaire/>
                                    </Route>
                                    <Route path="/ChoixCrematorium">
                                        <ChoixCrematorium/>
                                    </Route>
                                    <Route path="/ChoixUrne">
                                        <ChoixUrne/>
                                    </Route>
                                    <Route path="/ChoixEmbleme">
                                        <ChoixEmbleme/>
                                    </Route>
                                    <Route path="/ChoixCapiton">
                                        <ChoixCapiton/>
                                    </Route>
                                    <Route path="/ChoixCimetiere">
                                        <ChoixCimetiere/>
                                    </Route>
                                    <Route path="/ChoixHopital">
                                        <ChoixHopital/>
                                    </Route>
                                    <Route path="/ChoixMaisonDeRepos">
                                        <ChoixMaisonDeRepos/>
                                    </Route>
                                    <Route path="/ChoixEglise">
                                        <ChoixEglise/>
                                    </Route>
                                    <Route path="/ChoixVille">
                                        <ChoixVille/>
                                    </Route>
                                    <Route path="/ListeArticle">
                                        <ListeArticle/>
                                    </Route>
                                    <Route path="/Devis">
                                        <Devis/>
                                    </Route>
                                    <Route path="/ChoixCorbillard">
                                        <ChoixCorbillard/>
                                    </Route>
                                    <Route path="/page14">
                                        <Page14/>
                                    </Route>
                                    <Route path="/ChoixCercueil">
                                        <ChoixCercueil/>
                                    </Route>
                                    <Route path="/page12">
                                        <Page12/>
                                    </Route>
                                    <Route path="/page11">
                                        <Page11/>
                                    </Route>
                                    <Route path="/page10">
                                        <Page10/>
                                    </Route>
                                    <Route path="/page9">
                                        <Page9/>
                                    </Route>
                                    <Route path="/page8">
                                        <Page8/>
                                    </Route>
                                    <Route path="/page7">
                                        <Page7/>
                                    </Route>
                                    <Route path="/page6">
                                        <Page6/>
                                    </Route>
                                    <Route path="/page6b">
                                        <Page6b/>
                                    </Route>
                                    <Route path="/page6c">
                                        <Page6c/>
                                    </Route>
                                    <Route path="/page5">
                                        <Page5/>
                                    </Route>
                                    <Route path="/page4">
                                        <Page4/>
                                    </Route>
                                    <Route path="/page3">
                                        <Page3/>
                                    </Route>
                                    <Route path="/page2">
                                        <Page2/>
                                    </Route>
                                    <Route path="/">
                                        <Page1/>
                                    </Route>
                                </Switch>
                            </main>
                        </div>
                    </Router>
                    :   <div className="h-100 w-100 d-flex align-content-center justify-content-center flex-column" id="screensaver">
                            <div className="embed-responsive embed-responsive-16by9 h-50">
                                <video className="embed-responsive-item" src="https://img.funeralmanager.rip/liberte/LOGO.mp4" autoPlay={true} loop={true} />
                            </div>
                            <h1 className="font-gold text-center mt-5"><Typed
                                strings={['Vous venez de perdre un proche ?','Touchez l\'écran pour commencer']}
                                typeSpeed={60}
                                backSpeed={1}
                                loop={true}
                                backDelay={2000}
                                fadeOut={true}
                            /></h1>
                        </div>
            }

        </>
    );
}

export default App;
